import _ from 'lodash';
import moment from 'moment';
import { collectionSum } from '../../../services/calculateService';
const valuta = Intl.NumberFormat("nl-NL", { style: "currency",  currency: "EUR", });
const number = Intl.NumberFormat("nl-NL");

export function sum(data,labelx) {
    return _.sumBy(data, function(x) { return (x[labelx] != null) ? parseFloat(x[labelx]) : 0; })
}

export function calcValuta(x) {
    return valuta.format(x);
}

export function calcNumber(x) {
    return number.format(x);
}

export function roundNumber2Dec(x) {
    return Math.round(x * 100) / 100;
}

export function formatUnit(x,unit) {
    if(Number.isNaN(x) ) return '-';
    let y;
    if (unit==='valuta') {
        y = calcValuta(x);
    } else if (unit==='number') {
        y = calcNumber(x);
    } else if (unit==='number1') {
        y = calcNumber(_.round(x,1));
    } else if (unit==='integer') {
        y = calcNumber(Math.round(x));
    } else if (unit==='percentage') {
        y = `${calcNumber(_.round(x,2))} %`;
    } else if (unit==='percentage1') {
        y = `${calcNumber(_.round(x,1))}%`;
    } else if (unit==='seconds') {
        y = `${calcNumber(Math.round(x))} s (${calcNumber(_.round(x/60,1))} min)`;
    } else {
        y = `${x}`;
    }
    return y;
}

function calcTotal(data, labelx='net_sales_amount', unit='valuta', formula) {
    let total;
    let values = labelx.split(",");

    // Formulas
    if ((formula==='average') && (values.length>1)) {
        total = sum(data,values[0]) / sum(data,values[1]);
    } else {
        //total = _.sumBy(data, function(x) { return (x[labelx] != null) ? parseFloat(x[labelx]) : 0; });
        total = sum(data,values[0]);
    }
    return formatUnit(total,unit);
}


export function calculateKpi(data, options) {
    if (options.graph === 'TotalNumber' && Array.isArray(options.query_id) && options.x) {
        let xs = options.x.split(",");
        if (options.query_id.length>1 && xs.length>1) {
            let x1 = calcTotal(data[options.query_id[0]], xs[0], null);
            let x2 = calcTotal(data[options.query_id[1]], xs[1], null);
            return formatUnit(x1/x2,options.unit);
        } else {
            return '-';
        }
    } else if (options.graph === 'TotalNumber') {
        return calcTotal(data[options['query_id']], options.x, options.unit, options.formula);
    } else if (options.graph === 'BarChartHorizontal') {
        return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n);
    } else if (options.graph === 'BarChartVertical') {
        return calcBarVertical(data[options['query_id']], options.x, options.y, options.max_n,true);
    } else if (options.graph === 'PieChart') {
        return data[options['query_id']];
    } else if (options.graph === 'BarChartHorizontalClick') {
        return data[options['query_id']];
    } else {
        return null;
    }
}


export function check_avg_gross_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
}

export function cover_avg_with_covers(data, options) {
    if (typeof(options.query_id)==="string") return formatUnit(calcTotal(data[options['query_id']], options.x, options.unit, options.formula));
    let x1 = calcTotal(data['revenue_day'], 'net_with_covers', null);
    let x2 = calcTotal(data['covers'], 'covers', null);
    let y = x1/x2;
    
    return formatUnit(y,options.unit);
}

export function cover_n_avg(data, options) {
    if (typeof(options.query_id)==="string") return formatUnit(calcTotal(data[options['query_id']], options.x, options.unit, options.formula));
    let x1 = calcTotal(data['covers'], 'covers', null);
    let x2 = calcTotal(data['revenue_day'], 'checks_with_covers', null);
    let y = x1/x2;
    
    return formatUnit(y,options.unit);
}

export function labour_costs_p(data, options) {
    let x1 = calcTotal(data['labour'], 'timecard_cost', null);
    let x2 = calcTotal(data['revenue_day'], 'net_sales_amount', null);
    let y = x1/x2*100;

    return formatUnit(y,options.unit);
}

export function labour_costs_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
}

export function labour_clocked_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
}

export function productivity(data, options) {
    if (options.x) {
        let xs = options.x.split(",");
        let x1 = calcTotal(data[options.query_id[0]], xs[0], null);
        let x2 = calcTotal(data[options.query_id[1]], xs[1], null);
        let y = x1/x2;
        
        return formatUnit(y,options.unit);
    } else {
        return null;
    }
}

export function cover_avg_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0 && isFinite(x.amount));
}

export function labour_emp_absence(data, options) {
    let x1 = calcTotal(data['labour_emp'], 'sickness_hours', null);
    let x2 = calcTotal(data['labour_emp'], 'total_hours', null);
    let y = x1/x2*100;

    return formatUnit(y,options.unit);
}

export function labour_emp_absences(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, false).filter(x => x.amount !== 0);
}

export function revenue_group_restaurant(data, options) {
    let dataf = _.filter(data[options['query_id']] , {'revenue_group':'Restaurant'} );
    return calcTotal(dataf, options.x, options.unit);
}

export function revenue_group_banqueting(data, options) {
    let dataf = _.filter(data[options['query_id']] , {'revenue_group':'Banqueting'} );
    return calcTotal(dataf, options.x, options.unit);
}

export function revenue_group_hotel(data, options) {
    let dataf = _.filter(data[options['query_id']] , {'revenue_group':'Hotel'} );
    return calcTotal(dataf, options.x, options.unit);
}

export function calcBarHorizontal(data=[], labelx='net_sales_amount', labely='branchname', max_n=null, perc=false) {
    let data0 = _(data).groupBy(labely)
                        .map((value, key) => ({
                            label: key,
                            amount: _.sumBy(value, function(o) {
                                if(!o[labelx]) o[labelx]=0;
                                return parseFloat(o[labelx]); 
                            })
                        })).value();

    data0 = _.orderBy(data0, ['amount'], ['desc']);
    
    if (perc) {
        let total = collectionSum(data0,'amount');
        data0.map(x => x['show'] = `${calcValuta(x['amount'])} (${formatUnit(x['amount']/total*100,'percentage1')})`)
    }

    if (max_n && parseInt(max_n)) data0 = _.take(data0, parseInt(max_n));

    return data0;
}

function calcBarHorizontalFormula(data=[], labelx='net_sales_amount', labely='branchname', max_n=null, formula='', unit='valuta', order='desc') {
    let values = labelx.split(",");

    let data0 = data.map(d => {
        let total;
        // Formulas
        if ((formula==='average') && (values.length>1)) {
            total = d[values[0]] / d[values[1]];
            if (d[values[1]] === 0) total = 0;
        } else if ((formula==='percentage') && (values.length>1)) {
            total = d[values[0]] / d[values[1]] * 100;
            if (d[values[1]] === 0) total = 0;
        } else {
            total = parseFloat(d[values[0]]);
        }
        return {label: d[labely], amount: total, show: formatUnit(total,unit) }; 
    });

    data0 = _.orderBy(data0, ['amount'], [order]);
    if (max_n && parseInt(max_n)) data0 = _.take(data0, parseInt(max_n));

    return data0;
}

function calcBarStacked(data=[], labelx='net_sales_amount', labely='branchname', max_n=null, unit='valuta') {
    let values = labelx.split(",");

    let data0 = data.map(d => {
        let y = {label: d[labely] };
        values.map( v => y[v] = parseFloat(d[v]) || 0 );
        return y;
    });

    let orderby = (labelx==='planned_hours,total_hours') ? 'total_hours' : values[0];

    data0 = _.orderBy(data0, [orderby], ['desc']);
    if (max_n && parseInt(max_n)) data0 = _.take(data0, parseInt(max_n));

    return data0;
}

export function labour_emp_hours(data, options) {
    return calcBarStacked(data[options['query_id']], options.x, options.y, options.max_n, options.unit).filter(x => x.label);
}

export function revenue_branches(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true);
}

export function corrections_branches(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true).filter(x => x.amount !== 0);
}

export function corrections_net_branches(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true).filter(x => x.amount !== 0);
}

export function covers_branches(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n).filter(x => x.amount !== 0);
}

export function cover_avg_with_covers_branches(data, options) {
    if (typeof(options.query_id)==="string") {
        return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
    } else if ('revenue_day' in data && 'covers' in data) {
        let data1 = data['revenue_day'].map(d => {
            d['covers']=collectionSum(data['covers'],'covers',{branch:d.branch});
            return d; 
        });
        return calcBarHorizontalFormula(data1, options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
    } else {
        return [];
    }
}

export function productivity_branches(data, options) {
    if ('revenue_day' in data && 'labour' in data) {
        let data1 = data['revenue_day'].map(d => {
            d['total_hours']=collectionSum(data['labour'],'total_hours',{branch:d.branch});
            return d; 
        });
        return calcBarHorizontalFormula(data1, options.x, options.y, options.max_n, options.formula, options.unit).filter(x => x.amount !== 0);
    } else {
        return [];
    }
}

export function labour_costs_p_branches(data, options) {
    if ('revenue_day' in data && 'labour' in data) {
        let data1 = data['revenue_day'].map(d => {
            d['timecard_cost']=collectionSum(data['labour'],'timecard_cost',{branch:d.branch});
            return d;
        });
        return calcBarHorizontalFormula(data1, options.x, options.y, options.max_n, options.formula, options.unit, 'asc').filter(x => x.amount !== 0);
    } else {
        return [];
    }
}

export function corrections_p_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit, 'desc').filter(x => x.amount !== 0);
}

export function checks_branches(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n);
}

export function check_avg_branches(data, options) {
    return calcBarHorizontalFormula(data[options['query_id']], options.x, options.y, options.max_n, options.formula, options.unit);
}

export function revenue_revenue_centers(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true);
}

export function revenue_product_group(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true);
}

export function revenue_product(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true);
}

export function product_group_count(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n);
}

export function product_count(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n);
}

export function payment_types(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n, true);
}

export function sales_planning_rg(data, options) {
    return calcBarHorizontal(data[options['query_id']], options.x, options.y, options.max_n);
}

export function formatHours(key) {
    return moment.utc(key*3600*1000).format('HH:mm');
}

function missingBusinessHours(arr1) {
    if (arr1.length > 2) {
        arr1.sort();
        let tot = ['0-00:00','0-01:00','0-02:00','0-03:00','0-04:00','0-05:00', '0-06:00', '0-07:00', '0-08:00', '0-09:00', '0-10:00', '0-11:00', '0-12:00', '0-13:00', '0-14:00', '0-15:00', '0-16:00', '0-17:00', '0-18:00', '0-19:00', '0-20:00', '0-21:00', '0-22:00', '0-23:00', '1-00:00', '1-01:00', '1-02:00', '1-03:00', '1-04:00', '1-05:00', '1-06:00', '1-07:00', '1-08:00', '1-09:00', '1-10:00', '1-11:00', '1-12:00', '1-13:00', '1-14:00', '1-15:00', '1-16:00', '1-17:00', '1-18:00', '1-19:00', '1-20:00', '1-21:00', '1-22:00', '1-23:00'];
        let i1 = tot.indexOf(arr1[0]);
        let i2 = tot.indexOf(arr1[arr1.length-1]);
        if (i1 && i2) return tot.slice(i1,i2+1);
        else return [];
    } else {
        return [];
    }
}

function calcBarVertical(data=[], labelx='net_sales_amount', labely='branchname', max_n=null, perc=false) {
    if (labely==='business_hour') {
        
        let data1 = _.cloneDeep(data);
        data1.map(x => {
            x['orderby'] = `${x['daynight']}-${formatHours(x[labely])}`;
            return x;
        });

        let missing = missingBusinessHours( _.uniq(data1.map(x => x['orderby'])));
        missing.map(m => data1.push({orderby:m}));
        
        let data2 = _(data1).groupBy('orderby')
                            .map((value, key) => ({
                                label: key.split('-')[1],
                                orderby: key,
                                amount: _.sumBy(value, function(o) {
                                    if(!o[labelx]) o[labelx]=0;
                                    return parseFloat(o[labelx]);
                                })
                            })).value();
        data2 = _.orderBy(data2, ['orderby'], ['asc']);

        if (perc) {
            let total = collectionSum(data2,'amount');
            data2.map(x => x['show'] = `${calcValuta(x['amount'])} (${formatUnit(x['amount']/total*100,'percentage1')})`)
        }
    
        // if (max_n && parseInt(max_n)) data2 = _.take(data2, parseInt(max_n));
        return data2;
    } else {
        return [];
    }
}
