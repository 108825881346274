import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import DashboardTitle from '../common/dashboardTitle';
import { getData, getTrend } from '../../services/dataService';
import { dateToString, get_selection, weekdayInOtherYear, dayInOtherYear } from '../../services/calculateService';
import { toastError,toastWarning } from '../../services/toastService';
import Translation from '../../services/translationService';
import logger from '../../services/logService';
import Spinner from '../common/spinner';
import Widgets from './widgets';

function Dashboard(props) {
    let { id } = useParams();
    if (!id) id = props.defaultdb;
    let dashboard = _.filter(props.dashboards,{_id:id})[0] || _.filter(props.dashboards,{is_active:true})[0] || {};
    const [skip, setSkip] = useState(0);
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [selection, setSelection] = useState('');
    const [trends, setTrends] = useState([]);
    const [trends2, setTrends2] = useState(null);
    const [trends2active, setTrends2active] = useState(null);

    async function updateData() {
        let strstart = dateToString(props.startDate);
        let strend = dateToString(props.endDate);
        console.log('updateData', strstart,strend, get_selection(strstart,strend));

        setSelection( get_selection(strstart,strend) );
        setTrends( [] );
        setTrends2(null);
        setTrends2active(null);
        setLoading(true);
        const widgets = _.uniq( _.flatten( _.map(dashboard.widgets, 'query_id') ) );

        try {
            const { data } = await getData(strstart,strend, widgets.filter(x => x) );
            // if (data.error) toastWarning('We zijn onze database aan het updaten, daardoor kon niet alle data geladen worden. Probeer het over een aantal minuten nogmaals!',10000);
            if (data.error) toastError(<Translation>unexpected-error</Translation>);
            setData( data );
            setLoading(false);
        }
        catch (ex) {
            setLoading(false);
            if (ex.response) {
                toastError(ex.response.data);
            }
            logger.log(ex);
        }
    }

    useEffect(() => {
        // skip first because of the update of dateContext in opening new datepicker component
        if ( props.dashboards && skip ) updateData();
        setSkip(skip+1);
    }, [id, props.dashboards, props.endDate]);

    function widgetTrendHour(x) {
        if (typeof(x.query_id)==="string" && x.trend_hour_option && x.trendhour) {
            return `${x.query_id}_now`;
        } else if (Array.isArray(x.query_id) && x.trend_hour_option && x.trendhour) {
            return x.query_id.map(x => `${x}_now`);
        } else {
            return x.query_id;
        }
    }

    async function getTrends() {
        let strstart = dateToString(props.startDate);
        let strend = dateToString(props.endDate);
        
        const widgets = _.uniq( _.flatten( _.map(_.filter(dashboard.widgets,{trend:true}), widgetTrendHour) ) );
        const trendwidgets = widgets.filter( w => ['revenue_day','revenue_day_now','labour','labour_now','labour_hour','labour_hour_now','covers','covers_now','revenue_group','revenue_group_now','labour_emp','losses','revenue_same_hour'].includes(w) );
        if (trendwidgets.length) {
            console.log('getTrends()',strstart,strend);
            const { data: datat } = await getTrend(strstart,strend,trendwidgets);
            setTrends( datat );
        }
    }

    useEffect(() => {
        if (skip && !loading && !data.error) {
            getTrends();
        }
    }, [loading]);

    async function showtrend2() {
        if (trends2) setTrends2active( !trends2active );
        if (loading2 || trends2 || !['day','week','month'].includes(selection)) return null;

        setLoading2(true);
        let strstartdata = dateToString(props.startDate);
        let strstart = selection==='month' ? dayInOtherYear(props.startDate,-1) : weekdayInOtherYear(props.startDate,-1);
        let strend = selection==='month' ? dayInOtherYear(props.endDate,-1) : weekdayInOtherYear(props.endDate,-1);

        const widgets = _.uniq( _.flatten( _.map(_.filter(dashboard.widgets,{trend:true}), widgetTrendHour) ) );
        const trendwidgets = widgets.filter( w => ['revenue_day','revenue_day_now','labour','labour_now','labour_hour','labour_hour_now','covers','covers_now','revenue_group','revenue_group_now','labour_emp','losses','revenue_same_hour'].includes(w) );
        if (trendwidgets.length) {
            console.log('getTrends2()',strstart,strend);
            const { data: datat2 } = await getTrend(strstart,strend,trendwidgets,strstartdata);
            setTrends2active( true );
            setTrends2( datat2 );
            setLoading2(false);
        }
    }

    return (
        <main className="container-fluid">
            { loading ? <div className="loading center-dp "><Spinner /></div> : null}
            <DashboardTitle title={dashboard.title} trend2={['day','week','month'].includes(selection) && dashboard.trend2} showtrend2={showtrend2} trends2active={trends2active} sel={selection} />
            <Widgets dashboard={dashboard} data={data} trends={trends} trends2={trends2active && trends2} selection={selection} branches={props.branches} loading={loading}/>          
        </main>
    );
}

export default Dashboard;
