import React, { Component } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import NotFound from "./components/notFound";
import NavBar from "./components/navbar";
import Dashboards from './components/dashboards/dashboards';
import DashboardsNew from './components/dashboards_new/dashboard';
import DashboardsDyn from './components/dashboards_dynamic/dashboard';
import Report from './components/reports/reports';
import Profile from './components/userProfile';
import ManageUsergroups from './components/manageUsergroups';
import UsergroupForm from './components/usergroupForm';
import ManageUsers from './components/manageUsers';
import UserForm from './components/userForm';
import ManageChains from './components/manageChains';
import ChainForm from './components/chainForm';
import ManageRegions from './components/manageRegions';
import RegionForm from './components/regionForm';
import ManageAdminReportmailings from './components/manageAdminReportmailings';
import AdminReportmailingForm from './components/adminReportmailingForm';
import ManageDashboards from './components/manageDashboards';
import ManageAdminDashboards from './components/manageAdminDashboards';
import ManageCompanyDashboards from './components/manageCompanyDashboards';
import ManageVestaboards from './components/manageVestaboards';
import VestaboardForm from './components/vestaboardForm';
// import ManageVestaboardProducts from './components/manageVestaboardProducts';
// import VestaboardProductsForm from './components/vestaboardProductsForm';
import DashboardForm from './components/dashboardForm';
import AdminDashboardForm from './components/adminDashboardForm';
import CompanyDashboardForm from './components/companyDashboardForm';
import ManageTemplate from './components/manageTemplate';
import Home from './components/home';
import LoginForm from './components/loginForm';
import ForgotForm from './components/forgotForm';
import ResetForm from './components/resetForm';
import LogOut from './components/logout';
import auth from './services/authService';
import { changeSelectionsNav, changeFiltersNav, createOtherRegionChain } from './services/calculateService';
import DateContext from './context/dateContext';
import LanguageContext from './context/languageContext';
import { t } from './services/translationService';
import Menu from './components/menu';
import './App.css';

document.title = process.env.REACT_APP_NAME;

class App extends Component {
  state = { 
    count: 0,
    startDate: new Date(),
    endDate: new Date(),
    reports: [],
    lng: `${navigator.language || navigator.userLanguage || 'nl'}`.split('-')[0]
  };

  async componentDidMount() {
    let user = auth.getCurrentUser();
    if (user && (user.exp > (Date.now() / 1000))) {
      const userdata = await auth.getUserData();
      let lng = userdata.data.language || this.state.lng;
      
      let userregions = _.map(userdata.data.regions_join, o => ({ ...o, checked:true, count:true }) );
      let regions = [{_id:"allregions", "name": "allregions", "label":this.getChainRegionCountLabel(userregions,'regions',lng), "checked":true}, ...userregions ];
      if (regions.length < 2) {
        regions = [];
      } else {
        let otherregion = createOtherRegionChain(regions,userdata.data.branches_join);
        if (otherregion.branch_ids.length) {
          regions.push(otherregion);
          regions[0].label = this.getChainRegionCountLabel(regions,'regions',lng);
        }
      }

      let userchains = _.map(userdata.data.chains_join, o => ({ ...o, checked:true, count:true }) );
      let chains = [{_id:"allchains", "name": "allchains", "label":this.getChainRegionCountLabel(userchains,'chains',lng), "checked":true}, ...userchains];
      if (chains.length < 2) {
        chains = [];
      } else {
        let otherchain = createOtherRegionChain(chains,userdata.data.branches_join);
        if (otherchain.branch_ids.length) {
          chains.push(otherchain);
          chains[0].label = this.getChainRegionCountLabel(chains,'chains',lng);
        }
      }

      let userbranches = _.map(userdata.data.branches_join, o => ({ ...o, checked:true, show:true, count:true, show_chains:true, show_regions:true, order:o.label.toLowerCase() }) );
      let branches = [{_id:"allbranches", "name": "allbranches", "label":this.getBranchesCountLabel(userbranches,lng), "checked":true, "show":true, "show_chains": true, "show_regions": true, "order":"aaaa"}, ...userbranches];
      
      this.setState({
        client: userdata.data.company_join[0].name,
        clientshort: userdata.data.company_join[0].short_name,
        regions: regions,
        chains: chains,
        branches: branches,
        reports: userdata.data.reports_join,
        kpis: userdata.data.kpis_join,
        dashboards: userdata.data.dashboards_join,
        defaultdb: userdata.data.company_join[0].default_dashboard_id,
        dayparts: userdata.data.dayparts_join,
        lng
      });
    }
  }

  resetCount = () => {
    this.setState({ count: 0 });
  }

  changeDaterange = (d1,d2) => {
    this.setState({ startDate: d1, endDate: d2 });
  }

  changeLng = (lng) => {
    if (!lng) lng = `${navigator.language || navigator.userLanguage || 'nl'}`.split('-')[0];
    this.setState({ lng });
    this.updateFilterLabels(lng);
  }

  updateFilterLabels = (lng) => {
    let {branches, regions, chains} = this.state;
    if (branches.length && branches[0].label) branches[0].label = this.getBranchesCountLabel(branches,lng);
    if (regions.length && regions[0].label) regions[0].label = this.getChainRegionCountLabel(regions, 'regions',lng);
    if (chains.length && chains[0].label) chains[0].label = this.getChainRegionCountLabel(chains, 'chains',lng);
    this.setState({ branches, regions, chains });
  }

  getBranchesCountLabel = (branches, lng=null) => {
    let n = _.countBy( branches,  ({ show_chains, show_regions, count }) => show_chains && show_regions && count ).true;
    n = n ? n : 0;
    let m = _.countBy( branches,  ({ checked, show_chains, show_regions, count }) => checked && show_chains && show_regions && count ).true;
    m = m ? m : 0;
    if (!lng) lng = this.state.lng;
    return `${t('all',lng)} ${t('branches',lng).toLowerCase()} (${m}/${n})`;
    // return `Alle branches (${m}/${n})`;
  }

  getChainRegionCountLabel = (list, group, lng=null) => {
    let user = auth.getCurrentUser();
    let n = _.countBy( list,  ({ count }) => count ).true;
    n = n ? n : 0;
    let m = _.countBy( list,  ({ checked, count }) => checked && count ).true;
    m = m ? m : 0;
    if (!lng) lng = this.state.lng;
    if (group === 'chains' && user.cid.substring(0, 9) === "59f86ffeb") group = 'formulas';
    return `${t('all',lng)} ${t(group,lng).toLowerCase()} (${m}/${n})`;
  }

  changeSelection = (e, group, name) => {
    if (group === 'branches') {
      const branches = changeSelectionsNav(e, this.state.branches, group, name);
      branches[0].label = this.getBranchesCountLabel(branches);
      this.setState({ branches });
    } else if (group === 'regions') {
      const regions = changeSelectionsNav(e, this.state.regions, group, name);
      const branches = changeFiltersNav(e, regions, this.state.branches, group);
      branches[0].label = this.getBranchesCountLabel(branches);
      regions[0].label = this.getChainRegionCountLabel(regions, group);
      this.setState({ regions, branches });
    } else if (group === 'chains') {
      const chains = changeSelectionsNav(e, this.state.chains, group, name);
      const branches = changeFiltersNav(e, chains, this.state.branches, group);
      branches[0].label = this.getBranchesCountLabel(branches);
      chains[0].label = this.getChainRegionCountLabel(chains, group);
      this.setState({ chains, branches });
    }
  }

  render() {
    const { client, clientshort, regions, chains, branches, count, startDate, endDate, reports, dashboards, kpis, lng, defaultdb, dayparts } = this.state;
    return (
      <LanguageContext.Provider value={{ lng, dayparts, clientshort }}>
      <DateContext.Provider value={{ startDate, endDate, changeDaterange: this.changeDaterange }}>
        <ToastContainer />
        <NavBar client={client} regions={regions} chains={chains} branches={_.filter(branches, {"show_chains": true, "show_regions": true})} count={count} changeSelection={this.changeSelection} bellClick={this.resetCount}/>
        <Menu dashboards={dashboards} kpis={kpis} />
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/forgot-password" element={<ForgotForm />} />
            <Route path="/reset-password" element={<ResetForm />} />
            <Route element={<RequireAuth />}>
              <Route path="/logout" element={<LogOut />} />
              <Route path="/dashboards1/:id" element={<Dashboards startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} locations={_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true})} />} />
              <Route path="/dashboards2" element={<DashboardsNew startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} />} />
              <Route path="/dashboards/:id" element={<DashboardsDyn dashboards={dashboards} defaultdb={defaultdb} kpis={kpis} startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} />} />
              <Route path="/dashboards" element={<DashboardsDyn dashboards={dashboards} defaultdb={defaultdb} kpis={kpis} startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} />} />
              <Route path="/reports" element={<Report reports={reports.filter(r => r.is_active && r.download) } startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} />} />
              <Route path="/profile" element={<Profile changeLng={this.changeLng}/>} />
              <Route element={<RequireManager />}>
                <Route path="/managetemplate" element={<ManageTemplate />} />
                <Route path="/manageusergroups" element={<ManageUsergroups />} />
                <Route path="/manageusergroups/:id" element={<UsergroupForm />} />
                <Route path="/manageusers" element={<ManageUsers />} />
                <Route path="/manageusers/:id" element={<UserForm />} />
                <Route path="/managechains" element={<ManageChains />} />
                <Route path="/managechains/:id" element={<ChainForm />} />
                <Route path="/manageregions" element={<ManageRegions />} />
                <Route path="/manageregions/:id" element={<RegionForm />} />
                <Route path="/managereportmailings" element={<ManageAdminReportmailings />} />
                <Route path="/managereportmailings/:id" element={<AdminReportmailingForm />} />
                <Route path="/managepersonaldashboards" element={<ManageAdminDashboards />} />
                <Route path="/managepersonaldashboards/:id" element={<AdminDashboardForm />} />
                <Route path="/managecompanydashboards" element={<ManageCompanyDashboards />} />
                <Route path="/managecompanydashboards/:id" element={<CompanyDashboardForm />} />
                <Route path="/managevestaboards" element={<ManageVestaboards />} />
                <Route path="/managevestaboards/:id" element={<VestaboardForm branches={branches?.filter(b => b._id !== 'allbranches')}/>} />
                {/* <Route path="/managevestaboardproducts" element={<ManageVestaboardProducts />} /> */}
                {/* <Route path="/managevestaboardproducts/:id" element={<VestaboardProductsForm branches={branches?.filter(b => b._id !== 'allbranches')}/>} /> */}
              </Route>
              {/* <Route path="/reportmailings" element={<ManageReportmailings />} /> */}
              {/* <Route path="/reportmailings/:id" element={<ReportmailingForm />} /> */}
              <Route path="/personaldashboards" element={<ManageDashboards />} />
              <Route path="/personaldashboards/:id" element={<DashboardForm />} />
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<DashboardsDyn dashboards={dashboards} defaultdb={defaultdb} kpis={kpis} startDate={startDate} endDate={endDate} branches={ _.map(_.filter(branches, {"show_chains": true, "show_regions": true, 'checked': true}), o => o.name )} />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Route>

          </Routes>
      </DateContext.Provider>
      </LanguageContext.Provider>
    );
  }
}

function RequireAuth() {
  let location = useLocation();
  let user = auth.getCurrentUser();
  
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (user.exp < (Date.now() / 1000)) {
    auth.logout();
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

function RequireManager() {
  let location = useLocation();
  if (!auth.isManagerOrAdmin()) {
    return <Navigate to="/not-found" state={{ from: location }} />;
  }
  
  return <Outlet />;
}

export default App;
